<template>
	<Toast />
	<Utils ref='Utils' />
	<input hidden="true" title='打开要导入文件' class="newFile" id='choose_file' type="file" />
	<selectClassCard @cancelTag='cancelTag' @confirmTag='confirmTag' ref='slectClassCard'></selectClassCard>
	<iframe v-show='false' ref='save_file' src="saveFile/saveFile.html" frameborder="0"
		style="width: 100%;height:100px;"></iframe>
	<div class="p-grid" style="margin-bottom: -30px;">
		<Splitter :style="all_style" class="mb-5">
			<SplitterPanel id='text_content_container' :size="70" :minSize="10" style="overflow: scroll;">
				<!-- 				<div style="background-color: #2266FF;width: 100%;">123
					
				</div> -->
				<div style="position: relative;width: 100%;">
					<h5 class='file_static shadow-2'>文件{{file_name}}共有{{this_file_text.length}}个字(含空格)</h5>
					<!-- 					<div class='file_static shadow-2'>
						<h5 class='file_static shadow-2'>文件{{file_name}}共有{{this_file_text.length}}个字(含空格)</h5>
						</div> -->
				</div>
				<p id='text_container' contenteditable="false"
					style="font-size: 18px;border: none;padding:10px;margin-top: 30px;">
					<span v-for="(item, j) of text_list" style="padding:0px 3px 0px 3px;" :class='item.state'
						:this_key="j" :key='j' :id='item.id' :entity_id='item.entity_id' :title='"类别："+item.state'
						@mouseover="itemMouseover(j)" @mouseout="itemMouseout(j)"><i v-if='item.show_delete && item.state!="un_taged" && item.state!="pre_taged"'
							@click="connectTagToCreateEntity(item)" style="font-weight: 800;margin-right: 5px;" class='pi pi-link' title='连接标注'>
						</i>{{item.text}}
						<i v-if='item.show_delete' @click="deleteTag(j)" style="font-weight: 800;" class='pi pi-times'
							title='删除标注'>
						</i>
						<br v-if='item.state=="blank_line"'>
						<!-- <br v-if='item.state=="blank_line"'> -->
					</span>
				</p>
			</SplitterPanel>
			<SplitterPanel :size="30" style="overflow: scroll;padding:10px 0px 0px 10px;">
				<Menubar :model="nestedMenuitems">
				</Menubar>
				<!-- 						<Divider style="margin:20px 0px 20px 0px;" layout="horizontal" align="center">
							<span class="p-tag">{{current_view}}</span>
						</Divider> -->
				<!-- 						<h5 v-if='show_item=="entity"'
							style="padding: 5px;background-color: #E5E5E5;">
							文件名：{{file_name}},字数统计：{{this_file_text.length}}</h5> -->
				<!-- <div v-if='show_item=="entity"' style="background-color: #ededed;margin:10px 0px 10px 10px;"> -->
				<div v-show='show_item=="entity"' style="margin-top: 20px;">
					<div class="p-formgrid p-grid translation-item col-12"
						style="font-weight: 700;background-color:#f8f9fa;">
						<div class='md:col-6'>实体</div>
						<div class='md:col-4'>类别</div>
						<div class='md:col-2'>操作</div>
					</div>
					<div :style='list_style'>
						<div v-for="(item, j) of entity_list" :key="j">
							<div class='translation-item-2 col-12' :this_key='j' :id='item.id'>
								<div class='md:col-1'>
									<i class="img-number">{{j+1}}</i>
								</div>
								<div class='md:col-5' :title='"实体数量："+item.entity_id_list.length'>{{item.name}}
									<i @click="showList($event,j)" title='浏览' class='pi pi-chevron-circle-down'
										style="margin-top:2px;float: right;"></i>
									<div v-show="item.show_id_list" :id='item.id+"_view_id"' class="card word-list"
										:style="word_list_style">
										<ul class="p-autocomplete-items" v-for="(entity_id, i) of item.entity_id_list"
											:key="i">
											<p class="word-item" @click="item.show_id_list=false"
												@mouseout="cancelUnderLine(entity_id)" @mouseover="underLine(entity_id)"
												:title='entity_id' style="padding: 3px 3px 3px 8px;"
												v-html="i+1+'  '+item.name">
											</p>
										</ul>
									</div>
								</div>
								<div class='md:col-4' :title='item.tag.code+"_"+item.tag.definition'>
									{{item.tag.name}}
								</div>
								<div class='md:col-1'>
									<i class="pi pi-trash" style="margin-top:auto;" title="批量删除标注"
										@click="deleteTagEntity(j)" />
								</div>
								<div class='md:col-1'>
									<i class="pi pi-pencil" style="margin-top:auto;" title="批量编辑标注"
										@click="editTagEntity(j)" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-show='show_item=="corpus_list"' style="margin-top: 20px;">
					<div class="p-formgrid p-grid translation-item col-12"
						style="font-weight: 700;background-color:#f8f9fa;">
						<div class='md:col-7'>语料</div>
						<div class='md:col-4'>最后作者</div>
						<!-- <div class='md:col-1'></div> -->
					</div>
					<div :style='list_style' v-if='corpus_list.length>0'>
						<div v-for="(item, j) of corpus_list" :key="j">
							<div :title='item.time' class='translation-item-2 col-12' :this_key='j' :id='item.id'>
								<div class='md:col-7'><i class="img-number"
										style="margin-right: 5px;">{{j+1}}</i>{{item.name}}</div>
								<div class='md:col-3'>{{item.editor}}</div>
								<div class='md:col-1'>
									<i class="pi pi-trash" style="margin-top:auto;" title="删除语料"
										@click="deleteCorpus(item)" />
								</div>
								<div class='md:col-1'>
									<i class="pi pi-pencil" style="margin-top:auto;" title="编辑语料"
										@click="getCorpusInfo(item)" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-show='show_item=="chart_bar"' style="margin-top: 20px;">
					<div class='formgrid p-grid' style="display: flex;flex-direction: row;">
						<div class='col-12 lg:col-6'>
							<div class="flex flex-column align-items-center">
								<h5>实体统计</h5>
								<Chart type="doughnut" :data="entity_count" style="width:100%" />
							</div>
						</div>
						<div class='col-12 lg:col-6'>
							<div class="flex flex-column align-items-center">
								<h5>元组统计</h5>
								<Chart type="doughnut" :data="triple_count" style="width:100%" />
							</div>
						</div>
					</div>
					<Chart type="bar" :data="entity_bar_data" />
					<Chart type="bar" :data="triple_bar_data" />
				</div>
				<div v-show='show_item=="triple"'>
					<div v-if='show_triple_select' style="margin-top:20px;">
						<!-- <div class="card" style="margin-top: ;"> -->
						<!-- <h5>增加三元组</h5> -->
						<span class="p-buttonset">
							<Button label="隐藏" icon="pi pi-eye-slash" title='隐藏元组选择窗口' @click='hideTripleSelect()' />
							<Button label="刷新" icon="pi pi-sync" title='刷新实体列表' @click='flushEntityList()' />
							<Button label="取消" icon="pi pi-times" title='取消编辑当前三元组' @click='clearTmpTripleItem' />
							<Button label="确定" icon="pi pi-check" title='增加当前三元组' @click='addTmpTripleToTriplList' />
						</span>
						<div v-if='entity_list.length>0' style="margin-top: 20px;">
							<WordFilterInput class='field col' style='margin-bottom: 0px;margin-top: 10px;'
								ref='select_first_entity' :show_list_copy='false' @selectForTriple='selectForTriple'
								:filter_input_id_copy="'select_first_entity'" :show_name_copy="'name'"
								:show_clear_input_copy='true' :title_name_copy="'id'"
								:op_status_copy="'select_first_entity'" :placeholder_text_copy="'实体1'"
								:filter_state_copy="'relation_class'" :filter_op_copy='"un_same"'
								:list_copy='entity_list' :selected_words_copy="''" />
							<WordFilterInput class='field col' style='margin-bottom: 0px;margin-top: 10px;'
								ref='select_relation_entity' :show_list_copy='false' @selectForTriple='selectForTriple'
								:filter_input_id_copy="'select_relation_entity'" :show_name_copy="'name'"
								:title_name_copy="'id'" :show_clear_input_copy='true' :placeholder_text_copy="'关系'"
								:filter_state_copy="'relation_class'" :filter_op_copy='"same"'
								:op_status_copy="'select_relation_entity'" :list_copy='entity_list'
								:selected_words_copy="''" />
							<WordFilterInput class='field col' style='margin-bottom: 0px;margin-top: 10px;'
								ref='select_second_entity' :show_list_copy='false' @selectForTriple='selectForTriple'
								:filter_input_id_copy="'select_second_entity'" :show_name_copy="'name'"
								:filter_state_copy="'relation_class'" :filter_op_copy='"un_same"'
								:show_clear_input_copy='true' :title_name_copy="'id'"
								:op_status_copy="'select_second_entity'" :placeholder_text_copy="'实体2'"
								:list_copy='entity_list' :selected_words_copy="''" />
						</div>
						<!-- </div> -->
					</div>
					<TripleList style='background-color: darkred;padding: 5px;' :list_style_copy='list_style'
						@editTripleItem='editTripleItem' @selectTripleEntityId='selectTripleEntityId'
						@deleteTripleSpecificList='deleteTripleSpecificList' @deleteTripleList='deleteTripleList'
						@addSpecificListForTriple='addSpecificListForTriple' ref='triple_lst_ref'
						v-if='triple_list.length>0' :triple_list_copy='triple_list'>
					</TripleList>
				</div>
			</SplitterPanel>
		</Splitter>
	</div>
</template>

<script>
	import WordFilterInput from './WordFilterInput.vue';
	import selectClassCard from './selectClassCard.vue';
	import TripleList from './TripleList.vue';
	import ReadFileService from '../service/ReadFileService.js';
	import Utils from './Utils.vue';
	export default {
		name: '',
		props: {
			para_copy: {
				default: null,
				type: Object
			}
		},
		components: {
			'WordFilterInput': WordFilterInput,
			'selectClassCard': selectClassCard,
			'Utils': Utils,
			'TripleList': TripleList,
		},
		data() {
			return {
				all_style: {
					"height": "auto",
					"width": "100%",
				},
				list_style: {
					"height": "auto",
					"overflow-y": "auto",
					"background-color": "#ffffff",
				},
				// triple_list_style: {
				// 	"height": "auto",
				// 	"overflow-y": "auto",
				// 	"background-color": "#ffffff",
				// },
				word_list_style: {
					"width": "auto"
				},
				corpus_list: [],
				first_compound_entity:null,
				second_compound_entity:null,
				entity_bar_data: {
					labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
					datasets: [{
						label: '实体TOP 10',
						backgroundColor: '#00bb7e',
						data: [28, 48, 40, 19, 86, 27, 90]
					}]
				},
				triple_bar_data: {
					labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
					datasets: [{
						label: '元组TOP 10',
						backgroundColor: '#2f4860',
						data: [28, 48, 40, 19, 86, 27, 90]
					}]
				},
				entity_count: {
					labels: ['实体(总)', '实体(合)'],
					datasets: [{
						data: [300, 50],
						backgroundColor: [
							"#36A2EB",
							"#FFCE56"
						],
						hoverBackgroundColor: [
							"#36A2EB",
							"#FFCE56"
						]
					}]
				},
				triple_count: {
					labels: ['元组(总)', '元组(合)'],
					datasets: [{
						data: [300, 50],
						backgroundColor: [
							"#36A2EB",
							"#FFCE56"
						],
						hoverBackgroundColor: [
							"#36A2EB",
							"#FFCE56"
						]
					}]
				},
				para: this.para_copy, //此后para可在methods中操作
				current_tag_id_list: [],
				listboxValue: null,
				show_item: 'entity',
				current_view: '实体',
				show_choose_file: true,
				show_triple_select: true,
				pre_taged_count: 0,
				this_file_text: '',
				text_list: [],
				file_name: '帮助文档.json',
				file_type: 'corpus',
				current_entity_name: null,
				entity_list: [],
				delete_id_list: true,
				current_edit_triple_index: null, //当前编辑的triple index
				items: [{
						label: 'Update',
						icon: 'pi pi-refresh',
						command: () => {
							this.showItems('entity');
						},
					},
					{
						label: 'Delete',
						icon: 'pi pi-times',
						command: () => {
							this.showItems('entity');
						},
					},
					{
						separator: true
					},
					{
						label: 'Home',
						icon: 'pi pi-home'
					},
				],
				tmp_triple_item: {
					"id": "tmp_triple_item_id",
					"first_entity": {
						"entity_id_list": []
					},
					"relation_entity": {
						"entity_id_list": []
					},
					"second_entity": {
						"entity_id_list": []
					},
				},
				triple_list: [],
				nestedMenuitems: [{
						label: '语料',
						icon: 'pi pi-fw pi-folder-open',
						items: [{
								label: '导入语料',
								icon: 'pi pi-fw pi-inbox',
								command: () => {
									this.openFile('corpus');
								},
							},
							{
								label: '要素类别',
								icon: 'pi pi-fw pi-flag',
								items: [{
										label: '添加类别',
										icon: 'pi pi-fw pi-server',
										command: () => {
											this.openFile('add_class');
										},
									},
									{
										label: '替换类别',
										icon: 'pi pi-fw pi-sync',
										command: () => {
											this.openFile('fresh_class');
										},
									},
									{
										label: '默认类别',
										icon: 'pi pi-fw pi-flag',
										command: () => {
											this.loadDefaultClass();
										},
									},
								],
							},
						]
					},
					{
						label: '标注',
						icon: 'pi pi-fw pi-bookmark',
						items: [{
								label: '自动标注',
								icon: 'pi pi-fw pi-bolt',
								command: () => {
									this.cloudAutoTaged();
								},
							},
							{
								label: '自动抽取三元组',
								icon: 'pi pi-fw pi-paperclip',
								command: () => {
									this.autoExtractTripleList();
								},
							},
							{
								label: '加载标注',
								icon: 'pi pi-fw pi-tags',
								command: () => {
									this.readDataByFileName();
								},
							},
							{
								label: '保存Ctrl+S',
								icon: 'pi pi-fw pi-save',
								command: () => {
									this.saveDataToLocalAndCloud();
								},
							},
							{
								label: '导出标注',
								icon: 'pi pi-fw pi-arrow-circle-up',
								command: () => {
									this.outputEntity();
								},
							},
							{
								label: '清空标注',
								icon: 'pi pi-fw pi-trash',
								command: () => {
									this.clearEntityAndTriple();
								},
							},
						]
					},
					{
						label: '显示',
						icon: 'pi pi-fw pi-eye',
						items: [{
								label: '实体',
								icon: 'pi pi-fw pi-credit-card',
								command: () => {
									this.showItems('entity');
								},
							},
							{
								label: '元组',
								icon: 'pi pi-fw pi-link',
								command: () => {
									this.showItems('triple');
								},
							},
							{
								label: '选元组',
								icon: 'pi pi-fw pi-plus-circle',
								command: () => {
									this.show_triple_select = true;
									this.showItems('triple');
									this.resizeWin();
								},
							},
							{
								label: '统计',
								icon: 'pi pi-fw pi-chart-bar',
								command: () => {
									this.showItems('chart_bar');
								},
							},
							{
								label: '语料列表',
								icon: 'pi pi-fw pi-file',
								command: () => {
									this.showItems('corpus_list');
								},
							},
						]
					},
				],
			}
		},
		ReadFileService: null,
		created() {
			this.ReadFileService = new ReadFileService();
		},
		mounted() {
			// console.log('组件加载成功');
			var that = this;
			that.getSelectText();
			that.setSelectDraw();
			that.listenCtrlS();
			that.openFileListen();
			// that.readDataByFileName(); //加载标注
			that.resizeWin();
			that.readDefaultHelperText();
			window.onresize = () => {
				return (() => {
					that.resizeWin();
				})();
			};
		},
		computed: {
			show_translate_card() {
				return this.$appState.show_translate_card;
			}
		},
		methods: {
			resizeWin() {
				var windowHeight = document.documentElement.clientHeight;
				this.all_style.height = windowHeight - 75 + 'px';
				if (this.show_item == 'triple' && this.show_triple_select == true) {
					this.list_style.height = windowHeight - 455 + 'px';
				} else {
					this.list_style.height = windowHeight - 205 + 'px';
				}
				// console.log(windowHeight - 158);
			},
			loadDefaultClass() {
				this.$refs.slectClassCard.readFileFromJson();
				this.$refs.Utils.showSusscessInfo('加载默认类别成功');
			},
			readDefaultHelperText() {
				this.ReadFileService.getHelperText().then(data => {
					// console.log('默认数据',data);
					this.setCorpusInfo(data, '帮助文档.json');
					// this.item_class_list=[];
					// // this.saveClassToLocalstorage();
					// var that=this;
					// setTimeout(() => {
					// 	that.item_class_list=data.class_list;
					// 	that.saveClassToLocalstorage();
					// }, 1000)
				});
			},
			outputEntity() {
				var data = this.formateAllData();
				this.$refs.save_file.contentWindow.saveJsonFile(this.file_name, data);
			},
			flushEntityList() {
				var tmp_entity = JSON.parse(JSON.stringify(this.entity_list));
				this.entity_list = [];
				var that = this;
				setTimeout(() => {
					that.entity_list = tmp_entity;
					that.$refs.Utils.showSusscessInfo('刷新实体成功');
				}, 1000)
			},
			openFileListen() {
				var that = this;
				var this_e = document.getElementById('choose_file');
				this_e.addEventListener('change', function(e) {
					var file = e.currentTarget.files[0]; // js 获取文件对象
					var this_file_name = file.name;
					if (this_file_name.split('.').length != 2) {
						that.$refs.Utils.showError('只能打开文件名中含一个"."的文件，如test.json，而不是test.json.json');
						return
					}
					// console.log(this_file_name.split('.'));
					if (file) {
						if (file.type != 'text/plain' && file.type != 'application/json') {
							that.$refs.Utils.showError('暂时只支持utf-8编码的txt和json格式文档');
							return;
						}

						const reader = new FileReader();
						reader.readAsText(file); // 将文件读取为文本
						reader.onload = () => { // 文件读取完成后的回调
							if (that.file_type != 'corpus') {
								if (file.type == 'application/json') {
									var class_json_data = JSON.parse(reader.result);
									if (typeof(class_json_data.data.class_list) == "undefined") {
										that.$refs.Utils.showError('文件内容不符,请联系管理员查询文件格式');
										return;
									}
									//
									that.$refs.slectClassCard.flushItemClassList(class_json_data.data
										.class_list, that.file_type);
									that.$refs.Utils.showSusscessInfo('更新分类成功');
								}
							} else {
								// var this_file_text=null;
								if (file.type == 'application/json') {
									var json_data = JSON.parse(reader.result);

									if (typeof(json_data.this_file_text) == "undefined" || typeof(json_data
											.text_list) == "undefined" || typeof(json_data.entity_list) ==
										"undefined" || typeof(json_data.triple_list) ==
										"undefined") {
										that.$refs.Utils.showError('文件内容不符,仅支持打开本系统导出的json文件');
										return;
									}
									// console.log('不包含的对象',typeof(json_data.this_file_text));
									that.setCorpusInfo(json_data, file.name);
								} else {
									// console.log('文本内容切分r',reader.result.split('\r\n'));
									var this_result = reader.result.replace(/\n\r/g, "kg&split&blink&line");
									var second_result = this_result.replace(/[\n\r]/g, "kg&split&blink&line");
									//去除文字间空格
									that.this_file_text = second_result.replace(/\s+/g, "-");
									// console.log('文本内容切分n',reader.result.split('\n'));
									// that.this_file_text.replace(/\n\n/g,"\n");
									that.splitOriginalText();
									that.file_name = file.name;
								}

								// console.log('文件名分割',file.name.split('.'));
								var file_message = "读取文件" + file.name + "成功，共含" + that.this_file_text.length +
									"字符。";
								that.$refs.Utils.showSusscessInfo(file_message);
							}
						}
						reader.onerror = (e) => {
							// console.log(e, '????XXX')
							alert('文件读取错误,错误信息为：', e)
						}
					}

					this_e.value = '';
				});
			},
			setCorpusInfo(json_data, file_name) {
				this.this_file_text = json_data.this_file_text;
				this.text_list = json_data.text_list;
				this.entity_list = json_data.entity_list;
				this.triple_list = json_data.triple_list;
				this.file_name = file_name;
			},
			openFile(file_type) {
				var this_e = document.getElementById('choose_file');
				this_e.click();
				this.file_type = file_type;
			},
			listenCtrlS() {
				var that = this;
				document.addEventListener('keydown', function(e) {
					if (e.keyCode == 83 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
						that.saveDataToLocalAndCloud();
						//阻止浏览器事件
						e.preventDefault();
					}
				});
			},
			async cloudAutoTaged() {
				var resp = await this.$refs.Utils.autoTaged(this.text_list);
				if (resp.code == -1) {
					this.$refs.Utils.showError('自动标注失败，可能是云端服务未开启');
					return;
				}
				// console.log('返回的内容',resp);
				this.text_list = resp.data.text_list;
				this.entity_list = resp.data.entity_list;
				this.showItems('entity');
			},
			//设置标注框可动
			setSelectDraw() {
				let el = document.getElementById("blackSquare");
				//在该对象上绑定鼠标点击事件
				el.onmousedown = function(e) {
					//鼠标按下，计算鼠标触点距离元素左侧和顶部的距离
					let disX = e.clientX - el.offsetLeft;
					let disY = e.clientY - el.offsetTop;

					//鼠标松开时，注销鼠标事件，停止元素拖拽。
					document.onmouseup = function() {
						document.onmousemove = null;
						document.onmouseup = null;
					};

					document.onmousemove = function(e) {
						//计算需要移动的距离
						let tX = e.clientX - disX;
						let tY = e.clientY - disY;
						//移动当前元素
						if (tX >= 0 && tX <= window.innerWidth - el.offsetWidth) {
							el.style.left = tX + 'px';
						}
						if (tY >= 0 && tY <= window.innerHeight - el.offsetHeight) {
							el.style.top = tY + 'px';
						}
					};
				}
				//鼠标松开时，注销鼠标事件，停止元素拖拽。
				el.onmouseup = function() {
					document.onmousemove = null;
					document.onmouseup = null;
				};
			},
			//获取滑动获取的内容
			getSelectText() {
				var that = this;
				var text_container = document.getElementById('text_container');
				text_container.addEventListener('mouseup', function(e) {
					var sel = window.getSelection();
					if (sel.isCollapsed == true) return;
					//如果存在预标注对象，则返回
					if (that.pre_taged_count > 0) {
						that.showError('请先完成当前对象的标注');
						return;
					}

					var Range = sel.getRangeAt(0);
					var this_select = Range.cloneContents();
					//获取内容
					var new_div = document.createElement('div');
					new_div.appendChild(this_select);
					var spans = new_div.getElementsByTagName('span');
					// console.log('选择的内容Range',Range);

					//新分割流程
					if (spans.length > 1) {
						that.showError('不可跨行选择或选择已标注元素，请重新选择!');
					} else {
						var select_text = Range.toString();
						// console.log('选择的文本',select_text);
						that.addSpanBySplitText(select_text);
						that.showTagDialog(e);
					}


					// //旧分割标注流程
					// if (spans.length == 0) {
					// 	// console.log('选择的内容',Range.commonAncestorContainer.parentNode);
					// 	var single_span = Range.commonAncestorContainer.parentNode;
					// 	that.processSingleSpan(single_span, Range.startOffset, Range.endOffset);
					// 	that.pre_taged_count=1;
					// 	that.showTagDialog(e);
					// } else if (spans.length == 1) {
					// 	var this_key = parseInt(spans[0].getAttribute('this_key'));
					// 	this.text_list[this_key].state = 'pre_taged';
					// 	this.text_list[this_key].title = '地物要素';
					// 	that.pre_taged_count=1;
					// 	that.showTagDialog(e);
					// } else {
					// 	that.showError('不可选择已标注元素，请重新选择!');
					// }
				}, true);
			},
			//显示标注框
			showTagDialog(e) {
				let el = document.getElementById("blackSquare");
				//让标注框跟随移动
				let disX = e.clientX + 20;
				let disY = e.clientY + 20;
				el.style.left = disX + 'px';
				el.style.top = disY + 'px';
				this.$appState.show_select_card = true;
				this.$refs.slectClassCard.freshSelectNumber(this.current_tag_id_list.length);
			},
			//通过分割字符串来增加span
			addSpanBySplitText(select_text) {
				var new_text_list = [];
				var this_taged_id_list = [];
				for (var i = 0; i < this.text_list.length; i++) {
					var this_item = this.text_list[i];
					if (this_item.state == 'un_taged') {
						// console.log('未标注');

						//如果文字相等，那么直接赋值
						if (this_item.text == select_text) {
							this_item.state = 'pre_taged';
							this_item.title = '预标注对象';
							new_text_list.push(this_item);

							//记录此次标注的所有索引值，后面确认修改时批量修改状态
							this_taged_id_list.push(this_item.id);
						} else {
							//如果值不等，那么分割后赋值
							var split_result = this_item.text.split(select_text);
							for (var j = 0; j < split_result.length; j++) {
								var this_split_text = split_result[j];

								var taged_item = null;

								//当前值为空，说明等于选择的值
								if (this_split_text == '') {
									taged_item = this.buildSpan(select_text, '预标注', 'pre_taged', new_text_list.length);
									new_text_list.push(taged_item);

									this_taged_id_list.push(taged_item.id);
								} else {
									//如果单纯是非空，那就只增加到列表中，不增加预标注列表
									var normal_span = this.buildSpan(this_split_text, '未标注', 'un_taged', new_text_list
										.length);
									new_text_list.push(normal_span);
								}


								//如果处于两个非空之间，则在中间增加一个对象
								if (this_split_text != '' && j < split_result.length - 1 && split_result[j + 1] != '') {
									taged_item = this.buildSpan(select_text, '预标注', 'pre_taged', new_text_list.length);
									new_text_list.push(taged_item);

									this_taged_id_list.push(taged_item.id);
								}
							}

						}
					} else {
						//如果是已标注对象则直接添加
						new_text_list.push(this_item);
					}
				}
				// console.log('预标注index',this_taged_id_list);
				//当前实体的名称
				this.current_entity_name = select_text;
				this.current_tag_id_list = this_taged_id_list;
				this.pre_taged_count = this_taged_id_list.length;
				this.text_list = new_text_list;
				// console.log('预标注新列表',this.text_list);
			},
			splitOriginalText() {
				var new_text_list = [];
				var split_result = this.this_file_text.split('kg&split&blink&line');
				// console.log('分割结果',split_result);
				for (var j = 0; j < split_result.length; j++) {
					var this_split_text = split_result[j];

					var taged_item = null;

					//当前值为空，说明等于选择的值
					if (this_split_text == '') {
						taged_item = this.buildSpan('', '换行', 'blank_line', new_text_list.length);
						new_text_list.push(taged_item);
					} else {
						//如果单纯是非空，那就只增加到列表中，不增加预标注列表
						var normal_span = this.buildSpan(this_split_text.replace(/[\r\n]/g, "\n"), '未标注', 'un_taged',
							new_text_list
							.length);
						new_text_list.push(normal_span);
					}


					//如果处于两个非空之间，则在中间增加一个对象
					// if (this_split_text != '' && j < split_result.length - 1 && this_split_text[j + 1] != '') {
					// 	taged_item = this.buildSpan('', '空行', 'blank_line', new_text_list.length);
					// 	new_text_list.push(taged_item);
					// }
				}
				this.text_list = new_text_list;
				this.entity_list = [];
				this.triple_list = [];
			},
			buildSpan(this_text, this_title, this_type, id_number) {
				var this_span_item = {
					text: this_text,
					state: this_type,
					title: this_title,
					entity_id: null,
					id: this.buildId(id_number),
					show_delete: false,
					tag_item: null,
				}
				return this_span_item;
			},
			buildEntity(entity_name, tag_item, id_number,current_tag_id_list) {
				var entity_item = {
					"name": entity_name,
					"tag": tag_item,
					"id": this.buildId(id_number),
					"entity_id_list": current_tag_id_list,
					"show_id_list": false,
				}
				return entity_item;
			},
			buildId(id_number) {
				var this_time = new Date().getTime();
				var id = this_time + '_' + id_number;
				return id;
			},
			itemMouseover(index) {
				// console.log('触发over事件');
				if (this.text_list[index].state != 'un_taged' && this.text_list[index].state != 'blank_line') {
					this.text_list[index].show_delete = true;
				}
			},
			itemMouseout(index) {
				var that = this;
				setTimeout(() => {
					if (typeof(that.text_list[index]) != "undefined") {
						// console.log('触发out事件');
						that.text_list[index].show_delete = false;
					}
				}, 2000)
			},
			//链接实体以创造复合实体
			connectTagToCreateEntity(item){
				if (this.current_tag_id_list.length!=0){
					this.$refs.Utils.showError('请先处理当前选中的标注对象');
					return;
				}
				
				var split_result=item.state.split('_');
				if (split_result[split_result.length-1]=='compound'){
					this.$refs.Utils.showError('不可再次选择复合实体');
					return;
				}
				
				if (this.first_compound_entity==null){
					var that = this;
					setTimeout(() => {
						if (this.first_compound_entity!=null){
							this.$refs.Utils.showError('超过10s未指定第二复合实体，已自动取消第一实体');
							that.first_compound_entity=null;
						}
					}, 10000)
					this.first_compound_entity=item;
					this.$refs.Utils.showSusscessInfo('选择第一个实体成功，请选择第二个实体');
				}else{
					// console.log('选中对象',item);
					// console.log('第一复合实体',this.first_compound_entity);
					if (this.first_compound_entity.state!=item.state || this.first_compound_entity.id==item.id){
						this.$refs.Utils.showError('仅可链接两个类型相同的不同实体');
						return;
					}
					
					this.createCompoundEntity(this.first_compound_entity,item);
				}
			},
			createCompoundEntity(first_tag,second_tag){
				var new_name=first_tag.text+'...'+second_tag.text;
				var id_list=[first_tag.id,second_tag.id];
				//改变样式
				var first_tag_index=this.getIndexFromId(this.text_list,first_tag.id);
				var second_tag_index=this.getIndexFromId(this.text_list,second_tag.id);
				this.text_list[first_tag_index].state+='_compound';
				this.text_list[second_tag_index].state+='_compound';
				
				//删除旧id中的对象
				var first_entity_index=this.getIndexFromId(this.entity_list,first_tag.entity_id);
				this.deleteEntityIdList(first_entity_index,first_tag.id);
				
				var second_entity_index=this.getIndexFromId(this.entity_list,second_tag.entity_id);
				this.deleteEntityIdList(second_entity_index,second_tag.id);
				
				//新建实体
				var this_entity=this.buildEntity(new_name,first_tag.tag_item, this.entity_list.length,id_list)
				this.entity_list.push(this_entity);
				this.$refs.Utils.showSusscessInfo('构建复合实体成功');
				this.first_compound_entity=null;
				
				this.text_list[first_tag_index].entity_id=this_entity.id;
				this.text_list[second_tag_index].entity_id=this_entity.id;
			},
			getIndexFromId(list,id){
				for (var i=0;i<list.length;i++){
					if (list[i].id==id){
						return i;
					}
				}
			},
			//批量编辑标注
			editTagEntity(entity_index) {
				var this_entity = this.entity_list[entity_index];
				this.current_tag_id_list = this_entity.entity_id_list;
				this.$refs.slectClassCard.freshSelectNumber(this.current_tag_id_list.length);
				this.pre_taged_count = 0;
				this.current_entity_name = this_entity.name;
				this.$appState.show_select_card = true;
			},
			deleteTagEntity(entity_index) {
				var this_entity = this.entity_list[entity_index];
				this.current_tag_id_list = this_entity.entity_id_list;
				//删除对象
				this.delete_id_list = false;
				this.entity_list.splice(entity_index, 1);
				this.deleteTagByIdList();
				this.delete_id_list = true;
				this.$refs.slectClassCard.freshSelectNumber(0);
			},
			deleteTagByIdList() {
				//先将选择的对象取消标注
				// console.log('删除批注列表',this.current_tag_id_list);
				for (var i = 0; i < this.current_tag_id_list.length; i++) {
					// console.log('删除批注id',this.current_tag_id_list[i]);
					this.unTagedSpanById(this.current_tag_id_list[i]);
				}
				this.joinAllSpans();
				//刷新数量
				this.$refs.slectClassCard.freshSelectNumber(0);
			},
			joinAllSpans() {
				var new_text_list = [];
				var union_spans = null;
				//合并未标注对象
				for (var j = 0; j < this.text_list.length; j++) {
					var this_item = this.text_list[j];
					if (this_item.state != 'un_taged') {
						//遇到保持标注的对象，则将混合的加入后清空
						if (union_spans != null) {
							new_text_list.push(union_spans);
						}
						new_text_list.push(this_item);
						union_spans = null;
					} else {
						//遇到未保持标注的对象，则新增或者赋值
						if (union_spans != null) {
							union_spans.text += this_item.text;
						} else {
							union_spans = this_item;
						}
					}
				}

				if (union_spans != null) {
					new_text_list.push(union_spans);
				}

				this.text_list = new_text_list;
				// console.log('新列表',new_text_list);
			},
			unTagedSpanById(id) {
				var index = this.getCurrentIndexById(id);
				if (index == null) return;
				this.unTagedSpanByIndex(index);
			},
			unTagedSpanByIndex(index) {
				//处理已标注对象时需要换一种逻辑
				var option = 0;
				if (this.text_list[index].state != 'pre_taged') {
					option = 1;
				}

				//如果当前对象是预标注或者已标注对象，那么去掉current_tag_id_list中对应的id
				if (this.delete_id_list == true) {
					this.deleteCurrentTagIdList(this.text_list[index], option);
				}
				this.pureUntaged(index);
			},
			pureUntaged(index) {
				this.text_list[index].state = 'un_taged';
				this.text_list[index].title = '未标注';
				this.text_list[index].entity_id = null;
				this.text_list[index].tag_item = null;
				this.text_list[index].show_delete = false;
			},
			deleteCurrentTagIdList(this_item, option) {
				// console.log('当前元素',this_item);
				var current_index = null;
				if (option == 0) {
					//预标注的情况
					current_index = this.current_tag_id_list.indexOf(this_item.id);
					this.current_tag_id_list.splice(current_index, 1);
					this.pre_taged_count = this.current_tag_id_list.length;
					// console.log('删除元素后列表',this.current_tag_id_list);
				} else {
					//普通情况
					var entity_index = this.getCurrentIndexById(this_item.entity_id);

					if (entity_index == null) return;					
					this.deleteEntityIdList(entity_index,this_item.id);
				}
			},
			deleteEntityIdList(entity_index,this_item_id){
				//删除相应的实体id
				var this_entity_id_list = this.entity_list[entity_index].entity_id_list;
				var current_index = this_entity_id_list.indexOf(this_item_id);
				this_entity_id_list.splice(current_index, 1);
				
				// console.log('当前实体id',this_entity_id_list);
				if (this_entity_id_list.length == 0) {
					//如果删除了最后一个对象，则删除实体列表
					this.entity_list.splice(entity_index, 1);
				} else {
					this.entity_list[entity_index].entity_id_list = this_entity_id_list;
				}
			},
			getCurrentIndexById(id) {
				var this_element = document.getElementById(id);
				// console.log('当前对象',this_element);
				if (this_element != null) {
					var this_key = parseInt(this_element.getAttribute('this_key'));
					return this_key;
				} else {
					return null;
				}
			},
			deleteTag(index) {
				var split_result=this.text_list[index].state.split('_');
				if (split_result[split_result.length-1]=='compound'){
					this.$refs.Utils.showError('复合实体请到实体列表中删除');
					return;
				}
				
				this.unTagedSpanByIndex(index);
				this.delete_id_list == true;
				this.joinAllSpans();
				this.$refs.slectClassCard.freshSelectNumber(this.current_tag_id_list.length);
				//置空，这样就只能每次点击只能取消一次标注
				this.current_tag_index = [];
			},
			showError(msg) {
				this.$toast.add({
					severity: 'error',
					summary: 'Error Message',
					detail: msg,
					life: 3000
				});
			},
			showItems(item) {
				var view_title = {
					'entity': '实体',
					'triple': '三元组',
					'chart_bar': '统计',
				};
				this.current_view = view_title[item];
				this.show_item = item;
				//调整屏幕大小
				this.resizeWin();
				//如果是统计表，则统计
				switch (item) {
					case 'chart_bar':
						this.getStatisdic();
						break;
					case 'corpus_list':
						this.getCorpusList();
						break;
				}
			},
			//取消当前选择对象的标注
			cancelTag() {
				if (this.pre_taged_count > 0) {
					for (var i = 0; i < this.current_tag_id_list.length; i++) {
						var index = this.getCurrentIndexById(this.current_tag_id_list[i]);
						if (index == null) return;
						this.pureUntaged(index);
					}
					this.current_tag_id_list = [];
					this.pre_taged_count = 0;
					this.$refs.slectClassCard.freshSelectNumber(0);
					this.joinAllSpans();
				}else{
					//取消编辑状态
					this.current_tag_id_list = [];
					this.pre_taged_count = 0;
					this.$refs.slectClassCard.freshSelectNumber(0);
				}
			},
			confirmTag(tag_item) {
				if (this.current_tag_id_list.length == 0) return;
				var this_entity = this.buildEntity(this.current_entity_name, tag_item, this.entity_list.length,this.current_tag_id_list);
				// console.log('当前实体',this_entity);
				for (var i = 0; i < this.current_tag_id_list.length; i++) {
					var this_index = this.getCurrentIndexById(this.current_tag_id_list[i]);
					if (this_index != null) {
						this.text_list[this_index].state = tag_item.type;
						this.text_list[this_index].tag_item = tag_item;
						this.text_list[this_index].entity_id = this_entity.id;
					}
				}

				//pre_taged_count==0说明是当前对象多次调整类型

				if (this.entity_list.length == 0) {
					this.entity_list.push(this_entity);
				} else {
					if (this.pre_taged_count == 0) {
						this.entity_list[this.entity_list.length - 1] = this_entity;
					} else {
						this.entity_list.push(this_entity);
					}
				}
				// console.log('当前实体列表',this.entity_list);
				this.pre_taged_count = 0;
				// console.log('选中的标签',this.text_list[this.current_tag_index]);
			},
			formateAllData() {
				var data = {
					'this_file_text': this.this_file_text,
					'text_list': this.text_list,
					'entity_list': this.entity_list,
					'triple_list': this.triple_list,
				}
				var content = JSON.stringify(data);
				return content;
			},
			async saveDataToLocalAndCloud() {
				var content = this.formateAllData();
				localStorage.setItem(this.file_name, content);

				this.$refs.Utils.showSusscessInfo(this.file_name + '保存标注到缓存成功');
				var resp = await this.$refs.Utils.saveTaged(content, this.file_name + '.json');
				if (resp.code == 200) {
					this.$refs.Utils.showSusscessInfo(this.file_name + '云端保存标注成功');

					if (this.show_item == 'corpus_list') {
						this.getCorpusList();
					}
				} else {
					this.$refs.Utils.showError(this.file_name + '云端保存标注失败');
				}
			},
			async getCorpusInfo(corpus_meta) {
				var resp = await this.$refs.Utils.getCorpusInfo(corpus_meta);
				if (resp.code == 200) {
					this.setCorpusInfo(resp.data.corpus_info, corpus_meta.name);
				} else {
					this.$refs.Utils.showError('后台服务异常，获取失败');
				}
			},
			async deleteCorpus(corpus_meta) {
				//先备份保存文件到本地然后再删除云端文件
				this.outputEntity();
				this.$refs.Utils.showSusscessInfo('当前数据已备份到本地');
				var resp = await this.$refs.Utils.deleteCorpus(corpus_meta);
				if (resp.code == 200) {
					this.$refs.Utils.showSusscessInfo('云端同步删除数据成功，语料列表已更新');
					this.corpus_list = resp.data.corpus_list;
				} else {
					this.$refs.Utils.showError('后台服务异常，删除数据失败');
				}
			},
			async getCorpusList() {
				var resp = await this.$refs.Utils.getCorpusList();
				if (resp.code == 200) {
					this.corpus_list = resp.data.corpus_list;
				} else {
					this.$refs.Utils.showError('后台服务异常，获取列表失败');
				}
			},
			async getStatisdic() {
				var content = this.formateAllData();
				var resp = await this.$refs.Utils.getStatisdics(content);
				if (resp.code == 200) {
					this.$refs.Utils.showSusscessInfo(this.file_name + '统计完成');
					this.entity_count.datasets[0]['data'] = [resp.data.entity_sta_data.sum_number, resp.data
						.entity_sta_data.number
					];
					this.triple_count.datasets[0]['data'] = [resp.data.triple_sta_data.sum_number, resp.data
						.triple_sta_data.number
					];

					// barData: {
					// 	labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
					// 	datasets: [
					// 		{
					// 			label: '实体TOP 10',
					// 			backgroundColor: '#00bb7e',
					// 			data: [28, 48, 40, 19, 86, 27, 90]
					// 		}
					// 	]
					// },

					this.entity_bar_data.labels = resp.data.entity_sta_data.top_ten_names;
					this.entity_bar_data.datasets[0]['data'] = resp.data.entity_sta_data.top_ten_numbers;


					this.triple_bar_data.labels = resp.data.triple_sta_data.top_ten_names;
					this.triple_bar_data.datasets[0]['data'] = resp.data.triple_sta_data.top_ten_numbers;


					// console.log(this.barData);
					// console.log(resp.data.triple_sta_data);
				} else {
					this.$refs.Utils.showError(this.file_name + '后台服务异常，统计失败');
				}
			},
			readDataByFileName() {
				if (localStorage.getItem(this.file_name) !== null) {
					var this_file_data = JSON.parse(localStorage.getItem(this.file_name));
					this.text_list = this_file_data.text_list;
					this.entity_list = this_file_data.entity_list;
					this.triple_list = this_file_data.triple_list;
					this.$refs.Utils.showSusscessInfo(this.file_name + '加载标注成功');
				} else {
					this.$refs.Utils.showSusscessInfo(this.file_name + '无标注记录');
				}
			},
			//给三元组选择内容
			selectForTriple(item, key_name) {
				item['show_select'] = false;
				this.tmp_triple_item[key_name] = item;
				if (this.current_edit_triple_index != null) {
					this.setDefaultIdForSpecificList(item, key_name + '_list');
				}
				// switch (key_name) {
				// 	case 'first_entity':
				// 		this.setDefaultIdForSpecificList(item,key_name,key_name+'_list');
				// 		this.$refs.select_first_entity.freshSelectWord(set_name);
				// 		break;
				// 	case 'relation_entity':
				// 		this.$refs.select_relation_entity.freshSelectWord(set_name);
				// 		break;
				// 	case 'second_entity':
				// 		this.$refs.select_second_entity.freshSelectWord(set_name);
				// 		break;
				// 	default:
				// 		console.log('未指定相应函数');
				// }
			},
			//新增实体时将实体列表的第一个元素设置为默认值
			setDefaultIdForSpecificList(item, first_key) {
				for (var i = 0; i < this.tmp_triple_item.specific_list.length; i++) {
					this.tmp_triple_item.specific_list[i][first_key] = {
						'name': item['name'],
						'id': item['entity_id_list'][0],
					};
				}
			},
			getIndexByEntityId(first_key, text_id_list) {
				var entity_id_list = this.tmp_triple_item[first_key]['entity_id_list'];
				var index_list = [];
				for (var i = 0; i < entity_id_list.length; i++) {
					index_list.push(text_id_list.indexOf(entity_id_list[i]));
				}
				return index_list;
			},
			autoAddSpecificList() {
				var text_id_list = [];
				//依靠 text_list 的顺序，将entity id 按照顺序插入
				for (var i = 0; i < this.text_list.length; i++) {
					var this_item = this.text_list[i];
					if (this_item.state != 'un_taged' && this_item.state != 'pre_taged' && this_item.state !=
						'blank_line') {
						text_id_list.push(this_item.id);
						// // console.log(this_item);
						// entity_id_in_index.push({
						// 	"text": this_item,
						// 	"entity": entity_dict[this_item['entity_id']],
						// });
					}
				}
				var first_entity_index = this.getIndexByEntityId('first_entity', text_id_list);
				var relation_entity_index = this.getIndexByEntityId('relation_entity', text_id_list);
				var second_entity_index = this.getIndexByEntityId('second_entity', text_id_list);

				//自动连接，先找relation中的index，如果加一减一都存在于index表中
				var specific_list = [];
				for (var j = 0; j < relation_entity_index.length; j++) {
					//只有实体1、关系、实体2完全相邻时才会自动添加
					var tri = relation_entity_index[j];
					var tfi = first_entity_index.indexOf(tri - 1);
					var tsi = second_entity_index.indexOf(tri + 1);

					if (tfi != -1 && tsi != -1) {
						var tmp_data = {
							"show_id_list": false,
							"first_entity_list": {
								"name": this.tmp_triple_item.first_entity.name,
								"id": this.tmp_triple_item.first_entity.entity_id_list[tfi],
							},
							"relation_entity_list": {
								"name": this.tmp_triple_item.relation_entity.name,
								"id": this.tmp_triple_item.relation_entity.entity_id_list[j],
							},
							"second_entity_list": {
								"name": this.tmp_triple_item.second_entity.name,
								"id": this.tmp_triple_item.second_entity.entity_id_list[tsi],
							},
						};
						specific_list.push(tmp_data);
					}
				}

				//如果为空则增加一格默认的
				if (specific_list.length == 0) {
					specific_list = [{
						"show_id_list": false,
						"first_entity_list": {
							"name": this.tmp_triple_item.first_entity.name,
							"id": this.tmp_triple_item.first_entity.entity_id_list[0],
						},
						"relation_entity_list": {
							"name": this.tmp_triple_item.relation_entity.name,
							"id": this.tmp_triple_item.relation_entity.entity_id_list[0],
						},
						"second_entity_list": {
							"name": this.tmp_triple_item.second_entity.name,
							"id": this.tmp_triple_item.second_entity.entity_id_list[0],
						},
					}];
				}

				return specific_list;
				// console.log('first_entity_index',first_entity_index);
				// console.log('relation_entity_index',relation_entity_index);
				// console.log('second_entity_index',second_entity_index);
			},
			//增加临时元组到元组列表
			addTmpTripleToTriplList() {
				if (this.tmp_triple_item['first_entity']['entity_id_list'].length == 0 || this.tmp_triple_item[
						'relation_entity']['entity_id_list'].length == 0 || this.tmp_triple_item['second_entity'][
						'entity_id_list'
					].length == 0) {
					this.$refs.Utils.showError('三元组不完整');
					return;
				}

				//新增元组
				if (this.current_edit_triple_index == null) {
					this.tmp_triple_item['show_spcific_list'] = false;
					//加一个自动识别三元组specific_list的函数
					var specific_list = this.autoAddSpecificList();
					this.tmp_triple_item['specific_list'] = specific_list;
					this.tmp_triple_item['show_operate_list'] = false;
					this.tmp_triple_item['id'] = this.buildId(this.triple_list.length);
					//深拷贝
					var tmp_data = JSON.parse(JSON.stringify(this.tmp_triple_item));
					this.triple_list.push(tmp_data);
					this.$refs.Utils.showSusscessInfo('新增三元组成功');
				} else {
					//修改元组
					tmp_data = JSON.parse(JSON.stringify(this.tmp_triple_item));
					this.triple_list[this.current_edit_triple_index] = tmp_data;
					this.current_edit_triple_index = null;
					this.$refs.Utils.showSusscessInfo('修改三元组成功');
					this.clearTmpTripleItem();
				}
			},
			addSpecificListForTriple(list_item, triple_index) {
				list_item['id'] = this.buildId(this.triple_list[triple_index].specific_list.length);
				this.triple_list[triple_index].specific_list.push(list_item);
				this.triple_list[triple_index]['show_spcific_list'] = true;
			},
			deleteTripleList(triple_index) {
				this.triple_list.splice(triple_index, 1);
			},
			deleteTripleSpecificList(triple_index, specific_index) {
				this.triple_list[triple_index].specific_list.splice(specific_index, 1);
			},
			selectTripleEntityId(triple_index, specific_index, entity_tag, entity_id) {
				this.triple_list[triple_index].specific_list[specific_index][entity_tag]['id'] = entity_id;
				// console.log();
				// console.log(entity_tag);
				// console.log(entity_id);
			},
			clearEntityAndTriple() {
				this.entity_list = [];
				this.text_list = [];
				this.triple_list = [];
				this.clearTmpTripleItem();
				//再次拆分
				this.splitOriginalText();
			},
			clearTmpTripleItem() {
				this.tmp_triple_item = {
					"id": "tmp_triple_item_id",
					"first_entity": {
						"entity_id_list": []
					},
					"relation_entity": {
						"entity_id_list": []
					},
					"second_entity": {
						"entity_id_list": []
					},
				};
				this.$refs.select_first_entity.freshSelectWord('');
				this.$refs.select_relation_entity.freshSelectWord('');
				this.$refs.select_second_entity.freshSelectWord('');
				this.current_edit_triple_index = null;
			},
			showList(e, entity_index) {
				// //获取父级组件的宽度,并把父组件的宽度赋值给悬浮组件，由此来让悬浮组件继承宽度
				var parent_node = e.currentTarget.parentNode;
				this.word_list_style.width = parent_node.clientWidth.toString() + 'px';
				this.entity_list[entity_index].show_id_list = !this.entity_list[entity_index].show_id_list;

				var view_id = this.entity_list[entity_index].id + '_view_id';
				let el = document.getElementById(view_id);
				// console.log('对象',el);
				//让标注框跟随移动
				// let disX = e.clientX-parent_node.clientWidth+30;
				// console.log('disX',disX);
				let disY = e.clientY + 10;
				// el.style.left = disX + 'px';
				el.style.top = disY + 'px';
			},
			underLine(entity_id) {
				var this_span = document.getElementById(entity_id);
				this_span.setAttribute('style', "padding:3px;border:2px solid #ffffff;text-decoration: underline;");

				//视图移动到当前位置
				var text_content_container = document.getElementById('text_content_container');
				text_content_container.scrollTop = this_span.offsetTop - 100;
			},
			cancelUnderLine(entity_id) {
				var this_span = document.getElementById(entity_id);
				this_span.removeAttribute('style');
			},
			//获取 span index中的entity id
			getEntityIndexFromTextList(entity_dict) {
				var entity_id_in_index = [];
				//依靠 text_list 的顺序，将entity id 按照顺序插入
				for (var i = 0; i < this.text_list.length; i++) {
					var this_item = this.text_list[i];
					if (this_item.state != 'un_taged' && this_item.state != 'pre_taged' && this_item.state !=
						'blank_line') {
						// console.log(this_item);
						entity_id_in_index.push({
							"text": this_item,
							"entity": entity_dict[this_item['entity_id']],
						});
					}
				}
				return entity_id_in_index;
			},
			//转换实体列表
			transformEntityListToEntityDict() {
				var entity_dict = {};
				for (var i = 0; i < this.entity_list.length; i++) {
					var this_entity = this.entity_list[i];
					entity_dict[this_entity['id']] = this_entity;
				}

				return entity_dict;
			},
			autoAddTmpTripleData(first_entity, relation_entity, second_entity, specific_list) {
				if (first_entity.tag.type != 'relation_class') {
					this.tmp_triple_item['first_entity'] = first_entity;
				}

				this.tmp_triple_item['relation_entity'] = relation_entity;

				if (second_entity.tag.type != 'relation_class') {
					this.tmp_triple_item['second_entity'] = second_entity;
				}
				this.tmp_triple_item['show_spcific_list'] = false;
				this.tmp_triple_item['specific_list'] = specific_list;

				this.tmp_triple_item['id'] = this.buildId(this.triple_list.length);
				this.tmp_triple_item['show_operate_list'] = false;
				//深拷贝
				var tmp_data = JSON.parse(JSON.stringify(this.tmp_triple_item));
				this.triple_list.push(tmp_data);

				// this.addTmpTripleToTriplList();
				this.clearTmpTripleItem();
			},
			//合并三元组列表
			joinTripleList() {
				if (this.triple_list.length == 0) {
					return;
				}

				var new_triple_list = [];
				new_triple_list.push(this.triple_list[0]);

				for (var i = 1; i < this.triple_list.length; i++) {
					var this_triple = this.triple_list[i];

					for (var j = 0; j < new_triple_list.length; j++) {
						var this_new_triple = new_triple_list[j];
						var equal = this.compareTwoTriple(this_triple, this_new_triple);
						// console.log('相等状态',equal);
						if (equal) {
							// console.log('当前j',j);
							new_triple_list[j]['specific_list'].push(this_triple['specific_list'][0]);
							break;
						}
					}
					// console.log('当前j2',j);
					if (equal == false) {
						new_triple_list.push(this_triple);
					}
				}
				// console.log('new_triple_list', new_triple_list);
				this.triple_list = new_triple_list;
			},
			compareTwoTriple(this_triple, this_new_triple) {
				var first_entity_status = this.twoEntityEqual(this_triple, this_new_triple, 'first_entity');
				var relation_entity_status = this.twoEntityEqual(this_triple, this_new_triple, 'relation_entity');
				var second_entity_status = this.twoEntityEqual(this_triple, this_new_triple, 'second_entity');
				if (first_entity_status && relation_entity_status && second_entity_status) {
					return true;
				} else {
					return false;
				}
			},
			twoEntityEqual(this_triple, this_new_triple, first_key) {
				var this_have = Object.prototype.hasOwnProperty.call(this_triple[first_key], 'name');
				var this_new_have = Object.prototype.hasOwnProperty.call(this_new_triple[first_key], 'name');

				if (this_have && this_new_have) {
					if (this_triple[first_key]['name'] != this_new_triple[first_key]['name'] || this_triple[first_key][
							'tag'
						]['code'] != this_new_triple[first_key]['tag']['code']) {
						return false;
					} else {
						return true;
					}
				} else if (this_have || this_new_have) {
					return false;
				} else {
					return true;
				}
			},
			getUnRelationClass(entity_and_text, this_index, already_use_index) {
				var this_text = entity_and_text['text'];

				var this_entity = entity_and_text['entity'];
				var text_item = {};

				// console.log('当前entity_and_text',entity_and_text);
				if (this_entity.tag.type != 'relation_class') {
					//已经使用过该对象
					if (already_use_index.indexOf(this_index) != -1) {
						this_entity = this.getNullEntity();
						text_item = this.getTextList('', '');
					} else {
						//未使用过该对象，则往里增加一个id
						already_use_index.push(this_index);
						text_item = this.getTextList(this_entity.name, this_text.id);
					}
				} else {
					this_entity = this.getNullEntity();
					text_item = this.getTextList('', '');
				}

				var this_data = {
					"already_use_index": already_use_index,
					"this_entity": this_entity,
					"text_item": text_item,
				};
				return this_data;
			},
			getTextList(name, id) {
				return {
					"name": name,
					"id": id,
				}
			},
			getNullEntity() {
				return {
					'tag': {
						'type': 'relation_class'
					}
				};
			},
			getTripleFromEntityIdInIndex(entity_id_in_index) {
				//entity_id_in_index 为按照text_list索引顺序的entity list
				var already_use_index = [];
				var first_entity = {};
				var relation_entity = {};
				var second_entity = {};

				var relation_entity_list = {};
				var first_entity_list = {};
				var second_entity_list = {};

				for (var i = 0; i < entity_id_in_index.length; i++) {
					var this_entity = entity_id_in_index[i]['entity'];
					var this_text = entity_id_in_index[i]['text'];
					//实际上是其中一个特定对象
					relation_entity_list = this.getTextList(this_entity.name, this_text.id);

					if (this_entity.tag.type == 'relation_class') {
						// console.log(this_entity);
						relation_entity = this_entity;
						already_use_index.push(i);
						//位于第一个
						if (i == 0) {
							first_entity = this.getNullEntity();
							first_entity_list = this.getTextList('', '');

							var tpm_data = this.getUnRelationClass(entity_id_in_index[i + 1], i + 1,
								already_use_index);

							already_use_index = tpm_data['already_use_index'];
							second_entity = tpm_data['this_entity'];
							second_entity_list = tpm_data['text_item'];
							// second_entity=
							// already_use_index, second_entity,second_entity_list = this.getUnRelationClass(entity_id_in_index[i + 1],i+1,
							// 	already_use_index);
							// console.log('first_entity 1', first_entity);
						} else if (i == entity_id_in_index.length - 1) {
							//位于最后一个
							second_entity = this.getNullEntity();
							second_entity_list = this.getTextList('', '');
							tpm_data = this.getUnRelationClass(entity_id_in_index[i - 1], i - 1,
								already_use_index);

							already_use_index = tpm_data['already_use_index'];
							first_entity = tpm_data['this_entity'];
							first_entity_list = tpm_data['text_item'];

							// already_use_index, first_entity,first_entity_list = this.getUnRelationClass(entity_id_in_index[i - 1],i-1,
							// 	already_use_index);
							// console.log('first_entity 2',first_entity);
						} else {
							//位于中间
							tpm_data = this.getUnRelationClass(entity_id_in_index[i - 1], i - 1, already_use_index);
							already_use_index = tpm_data['already_use_index'];
							first_entity = tpm_data['this_entity'];
							first_entity_list = tpm_data['text_item'];

							var this_tmp_data = this.getUnRelationClass(entity_id_in_index[i + 1], i + 1,
								already_use_index);
							already_use_index = this_tmp_data['already_use_index'];
							second_entity = this_tmp_data['this_entity'];
							second_entity_list = this_tmp_data['text_item'];
							// console.log('first_entity 3',first_entity);
						}

						var specific_list = [{
							"first_entity_list": first_entity_list,
							"relation_entity_list": relation_entity_list,
							"second_entity_list": second_entity_list,
						}];

						// console.log('specific_list', specific_list);

						//加到临时列表
						this.autoAddTmpTripleData(first_entity, relation_entity, second_entity, specific_list);
					}
				}

				//合并相同的三元组列表
				this.joinTripleList();

			},
			//自动抽取三元组
			autoExtractTripleList() {
				//将所有已标注的 span 的 text_list index 和对应的 entity_list index取出
				//从text_list取标注对象的index
				//由于 text_list 中含有了 entity_id 所以，将 entity_list 转换成字典类型
				//然后根据 entity_id直接取出entity,这样就避免麻烦的查询了
				if (this.entity_list.length < 4) {
					this.$refs.Utils.showError('当前实体数量小于4，请手动抽取三元组');
				}
				//先清空三元组列表

				// console.log('开始抽取三元组');
				this.showItems('triple');
				this.triple_list = [];
				var that = this;
				var entity_dict = this.transformEntityListToEntityDict();
				var entity_id_in_index = this.getEntityIndexFromTextList(entity_dict);
				// console.log(entity_id_in_index);
				this.$refs.Utils.showSusscessInfo('自动抽取元组中');
				setTimeout(() => {
					that.getTripleFromEntityIdInIndex(entity_id_in_index);
				}, 1000);
				// console.log(entity_id_in_index);

				// this.showItems('none');
				// console.log(entity_dict);
			},
			editTripleItem(item, j) {
				this.tmp_triple_item = item;
				// console.log('triple item', item);
				this.setEntityName(item, 'first_entity');
				this.setEntityName(item, 'relation_entity');
				this.setEntityName(item, 'second_entity');
				this.current_edit_triple_index = j;
				this.resizeWin();
			},
			setEntityName(this_triple, first_key) {
				if (Object.prototype.hasOwnProperty.call(this_triple[first_key], 'name')) {
					var set_name = this_triple[first_key]['name'];
				} else {
					set_name = '';
				}

				switch (first_key) {
					case 'first_entity':
						this.$refs.select_first_entity.freshSelectWord(set_name);
						break;
					case 'relation_entity':
						this.$refs.select_relation_entity.freshSelectWord(set_name);
						break;
					case 'second_entity':
						this.$refs.select_second_entity.freshSelectWord(set_name);
						break;
					default:
						console.log('未指定相应函数');
				}

			},
			hideTripleSelect() {
				this.show_triple_select = false;
				this.resizeWin();
			},
		},
	}
</script>

<style scoped lang="scss">
	.img-number {
		float: left;
		// font-size: 18px;
		// margin-left: 5px;
		// font-weight: 700;
		// color:var(--primary-color);
		opacity: 0.5;
	}

	.pre_taged {
		background-color: var(--primary-color);
		border-radius: 3px;
		color: #FFFFFF;
	}

	.gis_class {
		background-color: #007BFF;
		color: #FFFFFF;
		border-radius: 3px;
	}
	
	.gis_class_compound {
		background-color: #007BFF;
		color: #FFFFFF;
		border-radius: 3px;
	}

	.time_class {
		background-color: #39b54a;
		color: #FFFFFF;
		border-radius: 3px;
	}
	
	.time_class_compound {
		background-color: #39b54a;
		color: #FFFFFF;
		border-radius: 3px;
	}

	.relation_class {
		background-color: #fbbd08;
		color: #FFFFFF;
		border-radius: 3px;
	}
	
	.relation_class_compound {
		background-color: #fbbd08;
		color: #FFFFFF;
		border-radius: 3px;
	}

	.topic_class {
		background-color: #e54d42;
		color: #FFFFFF;
		border-radius: 3px;
	}
	
	.topic_class_compound {
		background-color: #e54d42;
		color: #FFFFFF;
		border-radius: 3px;
	}

	.translation-item {
		display: flex;
		align-items: center;
		border-top: 1px solid #ededed;
		padding: 2px 0px 2px 0px;
	}

	.translation-item-2 {
		display: flex;
		align-items: center;
		// border-top: 1px solid #ededed;
		border-bottom: 1px solid #ededed;
		padding: 2px 0px 2px 0px;
	}

	.translation-item-2 i:hover {
		font-weight: 700;
		color: var(--primary-color);
	}

	.word-list {
		z-index: 3;
		position: absolute;
		float: left;
		width: auto;
		max-height: 140px;
		overflow-y: auto;
		overflow-x: auto;
		margin-top: 0.3125rem;
	}

	.word-item:hover {
		background-color: #1fa1fc;
		color: #ffffff;
	}

	.file_static {
		border-top-left-radius: 3px;
		padding: 5px;
		width: 100%;
		// max-width: 500px;
		background-color: var(--primary-color);
		color: var(--primary-color-text);
		position: absolute;
	}
</style>
