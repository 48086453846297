<template>
	<Toast></Toast>
	<div style="margin-bottom: 20px;width: 100%;">
		<div class="p-inputgroup" id="search_box">
			<InputText :id="filter_input_id" autocomplete="off" style="width: 100%;" :value="selected_words"
				@focusin="focusIn" type="text" @keyup="InputBlur" :placeholder="placeholder_text" />
			<Button v-if='show_clear_input' icon='pi pi-times' class="p-button-secondary" @click.stop="InitialWords"
				title="清空当前输入" />
			<Button icon='pi pi-chevron-down' @click="changeShowWord" title="关闭筛选列表" />
		</div>
		<div class="card-gray" id="word_container" :style="trans_style" v-if='show_list'>
			<div class="word-list" id='list-box'>
				<div style="max-height: 140px;height: auto; overflow: auto;overflow-x:auto;">
					<ul class="p-autocomplete-items" v-for="(item, j) of filtered_list" :key="j">
						<p class="word-item" :title='item[title_name]' @click="selectItem(item)"
							style="padding: 3px 3px 3px 8px;" v-html="item[show_name]"></p>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	// import NetWorkService from '../service/NetWorkService.js';
	import FilterWordsService from '../service/FilterWordsService.js';
	export default {
		name: 'WordFilterInput',
		props: {
			list_copy: {
				default: null,
				type: Array,
			},
			selected_words_copy: {
				default: null,
				type: String,
			},
			filter_input_id_copy: {
				default: 'filter_input',
				type: String,
			},
			op_status_copy: {
				default: '0',
				type: String,
			},
			show_list_copy: {
				default: true,
				type: Boolean,
			},
			show_name_copy: {
				default: 'name_code',
				type: String,
			},
			title_name_copy: {
				default: 'title',
				type: String,
			},
			show_clear_input_copy: {
				default: true,
				type: Boolean,
			},
			placeholder_text_copy: {
				default: '输入筛选',
				type: String,
			},
			filter_state_copy: {
				default: null,
				type: String,
			},
			filter_op_copy: {
				default: null,
				type: String,
			}
		},

		data() {
			return {
				op_status: this.op_status_copy,
				filter_input_id: this.filter_input_id_copy,
				list: this.list_copy, //此后para可在methods中操作
				filtered_list: this.list_copy, //筛选后的列表
				selected_words: this.selected_words_copy,
				show_list: this.show_list_copy,
				filter_state: this.filter_state_copy,
				filter_op: this.filter_op_copy,
				show_name: this.show_name_copy, //显示的字段名
				title_name: this.title_name_copy, //显示的title名
				show_clear_input: this.show_clear_input_copy, //显示一键清除框
				placeholder_text: this.placeholder_text_copy, //显示一键清除框
				trans_style: {
					"overflow": "auto",
					"height": "auto",
					"width": "100%",
				},
			}
		},
		// networkService: null,
		filterWordsService: null,
		components: {

		},
		created() {
			// this.networkService = new NetWorkService();
			this.filterWordsService = new FilterWordsService();
		},
		mounted() {
			// console.log('组件加载成功:',this.list);
			this.filterRelationList();
		},
		computed: {

		},
		methods: {
			focusIn() {
				this.filtered_list = this.list;
				this.clearList();
				this.show_list = true;
			},
			//定义是否展示单词列表
			changeShowWord() {
				this.show_list = !this.show_list;
				this.clearList();
				this.filtered_list = this.list;
			},
			InitialWords() {
				this.selected_words = '';
				this.clearList();
				this.filtered_list = this.list;
				document.getElementById(this.filter_input_id).value = '';
			},
			//监听单词输入框
			InputBlur() {
				this.clearList();
				var word_input = document.getElementById(this.filter_input_id);
				var keyword = word_input.value;
				// console.log("输入为：",keyword);
				if (!keyword) {
					this.InitialWords();
					return;
				}
				this.selected_words = keyword;
				var words = this.selected_words.split(' ');
				var new_key = '';
				if (words.length == 1) {
					new_key = this.selected_words;
				} else {
					new_key = words[words.length - 1]
				}
				this.filtered_list = this.filterWordsService.highLightWordByKey(new_key, this.list, this.show_name);
			},
			clearList() {
				this.filterRelationList();

				for (var i = 0; i < this.list.length; i++) {
					this.list[i][this.show_name] = this.list[i][this.show_name].replace(/<.*?>/ig, "");
				}
			},
			//选择单词对象
			selectItem(item) {
				item[this.show_name] = item[this.show_name].replace(/<.*?>/ig, "");
				item=JSON.parse(JSON.stringify(item));
				this.selected_words = item[this.show_name];
				switch (this.op_status) {
					case 'select_class':
						this.$emit('selectGisClass', item, 0, 0);
						break;
					case 'select_first_entity':
						this.$emit('selectForTriple', item, 'first_entity');
						this.show_list = false;
						break;
					case 'select_relation_entity':
						// if (item.tag.type != 'relation_class') {
						// 	this.$toast.add({
						// 		severity: 'error',
						// 		summary: '失败',
						// 		detail: '请选择关系实体',
						// 		life: 3000
						// 	});
						// 	// this.$refs.word_filter_utils.showError('请选择关系实体');
						// 	return;
						// }
						this.$emit('selectForTriple', item, 'relation_entity');
						this.show_list = false;
						break;
					case 'select_second_entity':
						this.$emit('selectForTriple', item, 'second_entity');
						this.show_list = false;
						break;
					default:
						console.log('未指定相应函数');
				}
			},
			freshSelectWord(this_text) {
				this.selected_words = this_text;
			},
			filterRelationList() {
				if (this.filter_state == null) {
					return;
				}
				var new_list = [];
				if (this.filter_state == 'relation_class' && this.filter_op == 'same') {
					new_list.push({
						"name": "等价",
						"tag": {
							"code": "关系属性",
							"type": "relation_class",
							"name": "要素关系",
							"name_code": "要素关系_relation_code",
							"definition": "传递、对称"
						},
						"id": "equal_id",
						"entity_id_list": [
							"equal_id_item"
						],
						"show_id_list": false
					});
				}
				for (var i = 0; i < this.list.length; i++) {
					if (this.list[i].tag.type == this.filter_state && this.filter_op == 'same' && this.list[i].name!='等价') {
						new_list.push(this.list[i]);
					}

					if (this.list[i].tag.type != this.filter_state && this.filter_op == 'un_same') {
						new_list.push(this.list[i]);
					}
				}
				// console.log('过滤完成');
				this.list = new_list;
			},
		}
	}
</script>

<style scoped lang="scss">
	.word-item {}

	.word-item:hover {
		background-color: #1fa1fc;
		color: #ffffff;
	}

	// .word-list{
	// 	z-index: 3;
	// 	position:absolute;
	// 	float: left;
	// }
</style>
