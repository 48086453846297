<template>
	<div :title='"实体数量："+entity.entity_id_list.length' style="align-items:center;">
		<span @mouseout="cancelUnderLine(specific_entity.id)" @mouseover="underLine(specific_entity.id)">{{specific_entity.name}}</span>
		<i @click="showList($event,entity.id+'_choose_id')" title='选择实体'
			class='pi pi-chevron-circle-down' style="margin-top:2px;float: right;"></i>
		<div v-show="specific_entity.show_id_list" :id="entity.id+'_choose_id'" class="card word-list" :style="word_list_style">
			<ul class="p-autocomplete-items" v-for="(entity_id, i) of entity.entity_id_list"
				:key="i">
				<p class="word-item" @mouseout="cancelUnderLine(entity_id)" @mouseover="underLine(entity_id)" :title='entity_id' @click="selectItem(entity_id)"
					style="padding: 3px 3px 3px 8px;" v-html="i+1+'  '+entity.name">
				</p>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: '',
		props: {
			entity_copy: {
				default: null,
				type: Object
			},
			specific_entity_copy: {
				default: null,
				type: Object
			},
			triple_index_copy: {
				default: null,
				type: Number
			},
			specific_index_copy: {
				default: null,
				type: Number
			},
			entity_tag_copy: {
				default: null,
				type: String
			}
		},
		components: {
		},
		data() {
			return{
				entity:this.entity_copy, //此后para可在methods中操作
				specific_entity:this.specific_entity_copy, //此后para可在methods中操作
				triple_index:this.triple_index_copy,
				specific_index:this.specific_index_copy,
				entity_tag:this.entity_tag_copy,
				word_list_style:{
					"width":"auto"
				},
			}
		},
		created() {
			
		},
		mounted() {
			
		},
		methods: {
			showList(e,entity_chhose_id){
				// //获取父级组件的宽度,并把父组件的宽度赋值给悬浮组件，由此来让悬浮组件继承宽度
				var parent_node=e.currentTarget.parentNode;
				this.word_list_style.width=parent_node.clientWidth.toString()+'px';
				this.specific_entity.show_id_list=!this.specific_entity.show_id_list;
				// console.log('对象id',entity_chhose_id);
				let el = document.getElementById(entity_chhose_id);
				// console.log('对象',el);
				//让标注框跟随移动
				// let disX = e.clientX-parent_node.clientWidth+30;
				// console.log('disX',disX);
				let disY = e.clientY+10;
				// el.style.left = disX + 'px';
				el.style.top = disY + 'px';
			},
			underLine(entity_id){
				//等价这一个关系时不下划线显示
				if (entity_id=='equal_id_item') return;
				var this_span=document.getElementById(entity_id);
				// this_span.setAttribute('style',"text-decoration: underline");
				this_span.setAttribute('style', "padding:3px;border:2px solid #ffffff;text-decoration: underline;");
				
				//视图移动到当前位置
				var text_content_container=document.getElementById('text_content_container');
				text_content_container.scrollTop = this_span.offsetTop-100;
			},
			cancelUnderLine(entity_id){
				var this_span=document.getElementById(entity_id);
				this_span.removeAttribute('style');
			},
			selectItem(entity_id){
				this.$emit('selectEntityId',this.triple_index,this.specific_index,this.entity_tag,entity_id);
				this.specific_entity.show_id_list=false;
			}
		},
		}
</script>

<style scoped lang="scss">
	.word-list {
		z-index: 3;
		position: absolute;
		// position: inherit;
		float: left;
		width: auto;
		max-height: 140px;
		overflow-y: auto;
		overflow-x: auto;
		margin-top: 0.3125rem;
	}
	
	.word-item:hover {
		background-color: #1fa1fc;
		color: #ffffff;
	}
	
	.translation-item-2 i:hover {
		font-weight: 700;
		color: var(--primary-color);
	}
</style>
